
import { Component, Vue } from 'vue-property-decorator'
import { apiDesignerDetail, apiDesignerFrozen, apiDesignerUnfrozen } from '@/api/shop'
import LsDialog from '@/components/ls-dialog.vue'
@Component({
  components: {
    LsDialog
  }
})
export default class DesignerDetail extends Vue {
    /** S Data **/
    id = ''
    isShowWallet = 1 // 1-钱包资产 0-分销

    // 用户信息
    user_info = {
      id: '', // 用户id
      sn: '', // 用户编号
      nickname: '', // 用户昵称
      avatar: '',
      earnings: '',
      total_earnings: '',
      create_time: '', // 创建时间
      update_time: '', // 最后的登录时间
      status: 1
    }

    // 验证规则
    userRules = {
      // name: [{
      // 	required: true,
      // 	message: '请输入规则名称',
      // 	trigger: 'blur'
      // }],
    }

    /** E Data **/

    /** S Methods **/
    // 获取用户信息
    userDetail () {
      apiDesignerDetail({
        id: this.id
      })
        .then((res: any) => {
          this.user_info = res
        })
        .catch((res: any) => {
          console.log(res)
        })
    }

    // 修改用户信息
    userSetInfo (val: number, id: string) {
      if (val == 1) {
        apiDesignerFrozen({
          id: id
        }).then(res => {
          this.userDetail()
        })
      } else {
        apiDesignerUnfrozen({
          id: id
        }).then(res => {
          this.userDetail()
        })
      }
    }

    // 冻结会员
    handleFrozen () {
      const { status, id } = this.user_info as any
      this.userSetInfo(status, id)
    }
    /** E Methods **/

    /** S Life Cycle **/
    created () {
      const query: any = this.$route.query
      if (query.id) {
        this.id = query.id
      }

      this.userDetail()
    }
    /** E Life Cycle **/
}
