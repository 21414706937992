import request from "@/plugins/axios";

/** S 商城列表 **/
// 商城列表
export const apiShopLists = (params: any) =>
  request.get("/shop.shop/lists", { params });

// 添加商城
export const apiShopAdd = (params: any) =>
  request.post("/shop.shop/add", params);

// 商城详情
export const apiShopDetail = (params: any) =>
  request.get("/shop.shop/detail", { params });

// 编辑商城
export const apiShopEdit = (params: any) =>
  request.post("/shop.shop/edit", params);

// 删除商城
export const apiShopDelete = (params: any) =>
  request.post("/shop.shop/delete", params);

// 修改超级管理员
export const apiShopChangeSuperAdmin = (params: any) =>
  request.post("/shop.shop/changeSuperAdmin", params);

// 修改商城状态
export const apiShopSwitchStatus = (params: any) =>
  request.post("/shop.shop/switchStatus", params);

// 历史套餐
export const apisetMealLogLists = (params: any) =>
  request.get("/shop.shop/setMealLogLists", { params });

// 修改商城备注
export const apiChangeRemark = (params: any) =>
  request.post("/shop.shop/changeRemark", params);

// 修改商家余额
export const apiSetUserWallet = (params: any) =>
  request.post("/shop.shop/adjustUserWallet", params);
/** E 商城列表 **/

/** S 套餐列表 **/
// 套餐列表
export const apiSetMealLists = (params: any) =>
  request.get("/shop.set_meal/lists", { params });

// 添加套餐
export const apiSetMealAdd = (params: any) =>
  request.post("/shop.set_meal/add", params);

// 套餐详情
export const apiSetMealDetail = (params: any) =>
  request.get("/shop.set_meal/detail", { params });

// 编辑套餐
export const apiSetMealEdit = (params: any) =>
  request.post("/shop.set_meal/edit", params);

// 删除套餐
export const apiSetMealDelete = (params: any) =>
  request.post("/shop.set_meal/del", params);

// 修改套餐状态
export const apiSetMealSwitchStatus = (params: any) =>
  request.post("/shop.set_meal/status", params);

/** E 套餐列表 **/

/** S 设计师管理 **/
// 设计师列表
export const apiDesignerLists = (params: any) =>
  request.get("/designer.designer/lists", { params });

// 设计师详情
export const apiDesignerDetail = (params: any) =>
  request.get("/designer.designer/detail", { params });

// 冻结设计师
export const apiDesignerFrozen = (params: any) =>
  request.get("/designer.designer/frozen", { params });

// 设计师解冻
export const apiDesignerUnfrozen = (params: any) =>
  request.get("/designer.designer/unfrozen", { params });

// 佣金收入记录
export const apiCommissLists = (params: any) =>
  request.get("/designer.commission/lists", { params });

// 佣金变动记录
export const apiDesignerLogLists = (params: any) =>
  request.get("/designer.log/lists", { params });

// 申请列表
export const apiApplyLists = (params: any) =>
  request.get("/designer.apply/lists", { params });

// 通过申请
export const apiApplyPass = (params: any) =>
  request.post("/designer.apply/pass", params);

// 拒绝申请
export const apiApplyRefuse = (params: any) =>
  request.post("/designer.apply/refuse", params);

// 图片列表
export const apiPicLists = (params: any) =>
  request.get("/designer.picture/lists", { params });

// 图片通过
export const apiPicPass = (params: any) =>
  request.post("/designer.picture/pass", params);

// 图片拒绝
export const apiPicRefuse = (params: any) =>
  request.post("/designer.picture/refuse", params);

// 图片上架
export const apiPicPut = (params: any) =>
  request.post("/designer.picture/put", params);

// 图片下架
export const apiPicOff = (params: any) =>
  request.post("/designer.picture/off", params);

// 图片全部通过
export const apiPicAllPass = (params: any) =>
  request.post("/designer.picture/allPass", params);

// 提现列表
export const apiWithdrawLists = (params: any) =>
  request.get("/designer.withdraw/lists", { params });

// 提现通过
export const apiWithdrawPass = (params: any) =>
  request.post("/designer.withdraw/pass", params);

// 提现拒绝
export const apiWithdrawRefuse = (params: any) =>
  request.post("/designer.withdraw/refuse", params);

// 查询结果
export const apiWithdrawSearch = (params: any) =>
  request.post("/designer.withdraw/search", params);

// 提现详情
export const apiWithdrawDetail = (params: any) =>
  request.get("/designer.withdraw/detail", { params });

/** E 设计师管理 **/

/** S ip品牌管理 **/
// 列表
export const apiIpLists = (params: any) =>
  request.get("/brand.ip/lists", { params });

// 添加
export const apiIpAdd = (params: any) => request.post("/brand.ip/add", params);

// 修改
export const apiIpEdit = (params: any) =>
  request.post("/brand.ip/edit", params);

// 删除
export const apiIpDel = (params: any) => request.post("/brand.ip/del", params);

// 状态更变
export const apiIpShow = (params: any) =>
  request.post("/brand.ip/show", params);

// 品牌图片
export const apiPictureLists = (params: any) =>
  request.get("/brand.picture/lists", { params });

// 品牌图片添加
export const apiPictureAdd = (params: any) =>
  request.post("/brand.picture/add", params);

// 品牌图片修改
export const apiPictureEdit = (params: any) =>
  request.post("/brand.picture/edit", params);

// 品牌图片删除
export const apiPictureDel = (params: any) =>
  request.post("/brand.picture/del", params);

/** E 设计师管理 **/


/** S 工厂管理 **/
// 工厂管理列表
export const apiFactoryLists = (params: any) =>
  request.get("/factory.factory/lists", { params });

// 工厂详情
export const apiFactoryDetail = (params: any) =>
  request.get("/factory.factory/detail", { params });

// 更改工厂状态
export const apiFactoryStatus = (params: any) =>
  request.post("/factory.factory/switchStatus", params);

// 添加工厂
export const apiFactoryAdd = (params: any) =>
  request.post("/factory.factory/add", params);

// 编辑工厂
export const apiFactoryEdit = (params: any) =>
  request.post("/factory.factory/edit", params);

// 编辑工厂
export const apiFactoryAdmin = (params: any) =>
  request.post("/factory.factory/detailadmin", params);

// 编辑工厂
export const apiFactoryAdminChange = (params: any) =>
  request.post("/factory.factory/changeSuperAdmin", params);

// 工厂删除
export const apiFactoryDel = (params: any) =>
  request.post("/factory.factory/delete", params);

//
export const apiAddressFields = (params: any) =>
  request.post("/factory.address/fields", params);

//
export const apiAddressSave = (params: any) =>
  request.post("/factory.address/save", params);
//
export const apiAddressDetail = (params: any) =>
  request.post("/factory.address/detail", params);

/** E 工厂管理 **/

/** S 订单管理 **/
// 订单列表
export const apiOrderLists = (params: any) =>
  request.get("/order.order/lists", { params });

// 订单详情
export const apiOrderDetail = (params: any) =>
  request.get("/order.order/detail", { params });

// 物流查询
export const apiOrderLogistics = (params: any) =>
  request.get("/order.order/logistics", { params });

// 修改工厂
export const apiOrderChangeFac = (params: any) =>
  request.post("/order.order/switchFactory", params);

// 发货
export const apiOrderDeliver = (params: any) =>
  request.post("/order.order/deliver", params);


/** E 订单管理 **/

/** S 留言管理 **/
// 留言列表
export const apiMessageLists = (params: any) =>
  request.get("/contacts.contacts/lists", { params });

// 留言详情
export const apiMessageDetail = (params: any) =>
  request.get("/contacts.contacts/detail", { params });

// 留言删除
export const apiMessageDel = (params: any) =>
  request.post("/contacts.contacts/delete", params);

/** E 订单管理 **/


//商户等级
// 等级列表
export const apiShopShopLevelLists = (params: any) => request.get('shop.shop_level/lists', { params })
// 删除等级
export const apiShopShopLevelDel = (data: any) => request.post('shop.shop_level/del', data)
// 新增等级
export const apiShopShopLevelAdd = (data: any) => request.post('shop.shop_level/add', data)
// 编辑等级
export const apiShopShopLevelEdit = (data: any) => request.post('shop.shop_level/edit', data)
// 等级详情
export const apiShopShopLevelDetail = (params: any) => request.get('shop.shop_level/detail', { params })
// 设置等级
export const apiShopShopSetLevel = (data: any) => request.post('/shop.shop/setlevel', data)


// 获取商户消耗能量点
export const apiSettingsPlatformGetPointConfig = (params: any) => request.get('/settings.platform/getpointConfig', { params })

// 设置商户消耗能量点
export const apiSettingsPlatformSetPointConfig = (params: any) => request.post('/settings.platform/setpointConfig', params)

// 商户明细
export const apiBalanceList = (params: any) => request.get('/shop.shop/consume', { params })

// 获取不可提现余额变动类型
export const apiBnwChangeType = (params: any) => request.get('/shop.shop/getChangeType', { params })
